import {Component, OnInit} from '@angular/core';
import {Location, NgForOf} from "@angular/common";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzIconDirective} from "ng-zorro-antd/icon";
import {NzInputModule} from "ng-zorro-antd/input";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NzTagModule} from "ng-zorro-antd/tag";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {ExaminationService} from "../../../core/services/examination/examination.service";
import {NotificationService} from "../../../shared/services/notification.service";

interface DataItem {
  id: string;
  name: string;
  totalNoQuestion: number;
  totalTime: string;
  status: string
}


@Component({
  selector: 'app-edit-examination',
  standalone: true,
  imports: [
    NzTableModule,
    NgForOf,
    NzButtonModule,
    NzTagModule,
    RouterLink,
    NzIconDirective,
    NzFormModule,
    ReactiveFormsModule,
    NzInputModule,
  ],
  templateUrl: './edit-release.component.html',
  styleUrl: './edit-release.component.scss'
})
export class EditReleaseComponent implements OnInit {
  setOfCheckedId = new Set<string>();
  checked = false;
  indeterminate = false;
  listOfData: DataItem[] = [];
  loading = false;
  form: FormGroup;
  jobId: string = '';

  constructor(
    private location: Location,
    private examinationService: ExaminationService,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private fb: FormBuilder) {
    this.form = this.fb.group({
      name: ['', Validators.required]
    })
  }

  ngOnInit() {
    const params = this.route.snapshot.params;
    this.jobId = params['jobId'];
    this.examinationService.getJobAssessReleaseIdByJobId(params['id'], params['jobId']).subscribe({
      next: data => {
        this.form.patchValue({
          name: data.versionName
        })
        this.listOfData = data.jobAssess.map(i => {
          if (i.isSelected) {
            this.setOfCheckedId.add(i.id)
          }
          return {
            id: i.id,
            name: i.name,
            totalNoQuestion: i.totalNoQuestion,
            totalTime: i.totalTime,
            status: i.status
          }
        });

      },
      error: e => {
        this.notification.apiError(e)
      }
    })
  }

  onBack() {
    this.location.back()
  }

  onSave(): void {
    if (this.form.valid) {
      this.examinationService.updateReleaseVersion({
        id: this.route.snapshot.params['id'],
        versionName: this.form.value.name,
        jobAssessIds: Array.from(this.setOfCheckedId)
      }).subscribe({
        next: () => {
          this.notification.success(`Update successful`, `${this.form.value.name} updated`)
        },
        error: e => {
          this.notification.apiError(e)
        }
      })
    } else {
      this.form.get('name')?.markAsDirty();
      this.form.get('name')?.updateValueAndValidity();
    }
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfData
      .forEach(({id}) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }


  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfData;
    this.checked = listOfEnabledData.every(({id}) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({id}) => this.setOfCheckedId.has(id)) && !this.checked;
  }
}
