import {Component, OnInit} from '@angular/core';
import {NzTableModule, NzTableSortFn} from "ng-zorro-antd/table";
import {Location, NgForOf} from "@angular/common";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzTagModule} from "ng-zorro-antd/tag";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {RankingService} from "../../core/services/ranking/ranking.service";
import {NzIconDirective} from "ng-zorro-antd/icon";
import {NotificationService} from "../../shared/services/notification.service";


interface DataItem {
  id: string;
  name: string;
  score: number;
  status: 'Pending Scheduling' | 'Awaiting Interview' | 'Interview Completed' | 'Not Selected' | 'Moved Forward' | string;
}

interface ListOfColumn {
  title: string;
  priority: boolean | number;
  showSort: boolean;
  compare: NzTableSortFn<any> | null;
  align: 'left' | 'center' | 'right' | null;
  width: string | null;
}

@Component({
  selector: 'app-ranking',
  standalone: true,
  imports: [
    NzTableModule,
    NgForOf,
    NzButtonModule,
    NzTagModule,
    RouterLink,
    NzIconDirective
  ],
  templateUrl: './ranking.component.html',
  styleUrl: './ranking.component.scss'
})
export class RankingComponent implements OnInit {
  listOfColumn: ListOfColumn[] = [
    {
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
      priority: false,
      showSort: false,
      align: null,
      width: null
    },
    {
      title: 'Status',
      compare: (a: DataItem, b: DataItem) => a.status.localeCompare(b.status),
      showSort: true,
      priority: false,
      align: 'center',
      width: '20%'
    },
    {
      title: 'Score',
      compare: (a: DataItem, b: DataItem) => a.score - b.score,
      showSort: true,
      priority: false,
      align: 'center',
      width: '20%'
    },
    {
      title: '',
      showSort: false,
      priority: false,
      compare: null,
      align: null,
      width: '250px'
    }
  ];
  listOfData: DataItem[] = [];
  loading = false;

  constructor(
    private rankingService: RankingService,
    private route: ActivatedRoute,
    private location: Location,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    
    const id = this.route.snapshot.params['id'];
    this.loading = true;
    this.rankingService.getRankingById(id, 1, 100).subscribe({
      next: data => {
        this.listOfData = data.data.map(a => {
          return {
            id: a.applicantId,
            name: a.fullName,
            score: a.overallMatch,
            status: 'Pending Scheduling'
          }
        }).sort((a, b) => b.score - a.score);
        this.loading = false
      },
      error: e => {
        this.notificationService.apiError(e)
      }
    })
  }

  onBack() {
    this.location.back()
  }
}
