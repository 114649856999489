<div style="margin-bottom: 16px">
  <button (click)="onBack()" nz-button><span nz-icon nzTheme="outline" nzType="arrow-left"></span>Back</button>
</div>
<div class="candidate fade-in-text">
  <div class="candidate__detail">
    <div class="candidate__detail__name">
      Applied Position: {{ candidate?.jobTitle }}
    </div>
    <hr>

    <div class="candidate__detail__image mt-16 d-flex d-jus-between">
      <img [src]="candidate?.image" alt="candidate">
    </div>

    <div class="mt-16 candidate__detail__name ">{{ candidate?.name }}</div>

    <div class="w-100 d-flex ">
      <div class="candidate__detail__label ">Phone number:</div>
      <div class="candidate__detail__data "> {{ candidate?.mobile }}</div>
    </div>
    <div class="w-100 d-flex a-delay-1000">
      <div class="candidate__detail__label ">Email:</div>
      <div class="candidate__detail__data "> {{ candidate?.mail }}</div>
    </div>
    <div class="mt-24">
      <h2>Score</h2>
      <div class="d-flex mt-24" style="width: 100%;">
        <div class="d-flex" style="width: 100%; gap: 24px; align-items: center">
          <div>
            <nz-progress
              [nzPercent]="score | async"
              [nzShowInfo]="true"
              [nzStatus]="'normal'"
              [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
              nzType="circle"
              style="width: 100%;"
            ></nz-progress>
          </div>
          <div class="w-100">
            <div>
              <nz-row>
                <div nz-col nzSpan="4">Skills</div>
                <div nz-col nzSpan="15">
                  <nz-progress
                    [nzPercent]="candidate?.skillMatch"
                    [nzStatus]="'normal'"
                    [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                    style="width: 100%; float: left"
                  ></nz-progress>
                </div>
              </nz-row>
            </div>
            <div>
              <nz-row>
                <div nz-col nzSpan="4">Experience</div>
                <div nz-col nzSpan="15">
                  <nz-progress
                    [nzPercent]="candidate?.expMatch"
                    [nzStatus]="'normal'"
                    [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                    style="width: 100%; float: left"
                  ></nz-progress>
                </div>
              </nz-row>
            </div>
            <div>
              <nz-row>
                <div nz-col nzSpan="4">Education</div>
                <div nz-col nzSpan="15">
                  <nz-progress
                    [nzPercent]="candidate?.educationMatch"
                    [nzStatus]="'normal'"
                    [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                    style="width: 100%; float: left"
                  ></nz-progress>
                </div>
              </nz-row>
            </div>
            <div>
              <nz-row>
                <div nz-col nzSpan="4">Soft Skills</div>
                <div nz-col nzSpan="15">
                  <nz-progress
                    [nzPercent]="candidate?.softSkillMatch"
                    [nzStatus]="'normal'"
                    [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                    style="width: 100%; float: left"
                  ></nz-progress>
                </div>
              </nz-row>
            </div>
            <div>
              <nz-row>
                <div nz-col nzSpan="4">Job Title</div>
                <div nz-col nzSpan="15">
                  <nz-progress
                    [nzPercent]="candidate?.jobTitleMatch"
                    [nzStatus]="'normal'"
                    [nzStrokeColor]="{ '0%': '#f87290', '50%': '#de91f5', '100%': '#568af6' }"
                    style="width: 100%; float: left"
                  ></nz-progress>
                </div>
              </nz-row>
            </div>

          </div>
          <!--                    <nz-progress-->
          <!--                      [nzPercent]="score | async"-->
          <!--                      [nzStrokeColor]="{ '0%': '#108ee9', '50%': '#2db7f5', '100%': '#87d068' }"-->
          <!--                      style="width: 100%;"-->
          <!--                    ></nz-progress>-->
          <!--          <nz-row>-->
          <!--            <nz-col [nzSpan]="5">-->
          <!--              <nz-statistic [nzTitle]="'Skills'"-->
          <!--                            [nzValue]="'90%'"></nz-statistic>-->
          <!--            </nz-col>-->
          <!--            <nz-col [nzSpan]="5">-->
          <!--              <nz-statistic [nzTitle]="'Experience'"-->
          <!--                            [nzValue]="'90%'"></nz-statistic>-->
          <!--            </nz-col>-->
          <!--            <nz-col [nzSpan]="5">-->
          <!--              <nz-statistic [nzTitle]="'Education'"-->
          <!--                            [nzValue]="'90%'"></nz-statistic>-->
          <!--            </nz-col>-->
          <!--            <nz-col [nzSpan]="5">-->
          <!--              <nz-statistic [nzTitle]="'Soft Skills'"-->
          <!--                            [nzValue]="'90%'"></nz-statistic>-->
          <!--            </nz-col>-->
          <!--            <nz-col [nzSpan]="4">-->
          <!--              <nz-statistic [nzTitle]="'Job Title'"-->
          <!--                            [nzValue]="'90%'"></nz-statistic>-->
          <!--            </nz-col>-->
          <!--          </nz-row>-->
        </div>

        <!--        <div style="width: 40%">-->
        <!--          &lt;!&ndash;          <canvas id="canvas">{{ chart }}</canvas>&ndash;&gt;-->
        <!--        </div>-->
      </div>
    </div>

    <div style="margin-top: 64px">
      <div>
        <h3>Comments</h3>
      </div>
      <nz-comment nzAuthor="AI Recruit">
        <nz-avatar
          [nzText]="'AI'"
          class="ai-bg"
          nz-comment-avatar
        ></nz-avatar>
        <nz-comment-content>
          <p>{{ candidate?.conclusion }}
          </p>
        </nz-comment-content>
      </nz-comment>
    </div>

  </div>

  <div *ngIf="pdfSrc" class="candidate__resume">
    <pdf-viewer [autoresize]="true"
                [fit-to-page]="true"
                [original-size]="false"
                [render-text]="true"
                [show-all]="true"
                [src]="pdfSrc"
                [stick-to-page]="true"
    ></pdf-viewer>
  </div>
</div>
