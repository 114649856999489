import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CandidateResponse} from "./candidate.model";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  constructor(private http: HttpClient) {
  }

  candidateProfile(id: string): Observable<CandidateResponse> {
    const url = `${environment.apiUrl}/applicants/${id}`;
    return this.http.get<CandidateResponse>(url)
  }


  getResume(id: string): Observable<Blob> {
    const url = `${environment.apiUrl}/applicants/${id}/download`;
    return this.http.get(url, {responseType: 'blob'})
  }
}
