import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {RegisterPayload, RegisterResponse} from "./register.model";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(private http: HttpClient) {
  }

  register(payload: RegisterPayload): Observable<RegisterResponse> {
    const url = environment.apiUrl + '/applicants';
    const formData = new FormData();
    formData.append('file', payload.file);  // Add file
    formData.append('image', payload.image);  // Add file
    formData.append('data', new Blob([JSON.stringify(payload.data)], {type: 'application/json'}));
    return this.http.post<RegisterResponse>(url, formData)
  }

}
