import {Component} from '@angular/core';
import {NzResultModule} from "ng-zorro-antd/result";
import {NzButtonModule} from "ng-zorro-antd/button";

@Component({
  selector: 'app-result',
  standalone: true,
  imports: [NzResultModule, NzButtonModule],
  templateUrl: './result.component.html',
  styleUrl: './result.component.scss'
})
export class ResultComponent {

}
