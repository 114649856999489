import {FormArray, FormControl, FormGroup} from "@angular/forms";

export function validateForm(form: FormGroup | FormArray) {
  if (form.invalid) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);

      if (control instanceof FormControl) {
        control.markAsDirty();
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        validateForm(control);  // Recursive call for nested FormGroups or FormArrays
      }
    });
  }
}
