<div class="exam-page">
  <div class="exam-page__content">
    <form [formGroup]="form">
      <ng-container formArrayName="exams">
        <div *ngFor="let f of examFormArray.controls; let fi = index;">
          <div [formGroup]="$any(f)">
            @for (i of exams; track i.id) {
              <div class="exam__question">{{ i.question }}</div>
              <div class="exam__choices">
                <nz-radio-group formControlName="answer">
                  @for (c of i.choices; track c) {
                    <div nz-radio [nzValue]="c.id" class="exam__choices__item">{{ c.label }}</div>
                  }
                </nz-radio-group>
              </div>
            }
          </div>
        </div>
      </ng-container>

    </form>
  </div>
</div>
