<nz-spin [nzSpinning]="loading" nzTip="Loading...">

  <div style="margin-bottom: 16px">
    <button (click)="onBack()" nz-button><span nz-icon nzTheme="outline" nzType="arrow-left"></span>Back</button>
  </div>
  <div class="create-examination">
    <div class="create-examination__content">
      <div><h2>Create Examination</h2></div>

      <div>
        <form [formGroup]="examinationForm" nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label nzRequired>Examination Name</nz-form-label>
            <nz-form-control nzErrorTip="Examination Name is required!"><input formControlName="name" nz-input
                                                                               type="text">
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label>Time Allowed</nz-form-label>
            <nz-form-control><input formControlName="time" nz-input
                                    placeholder="format: 00:00:00" type="text">
            </nz-form-control>
          </nz-form-item>
          <nz-card nzTitle="Generate questions by AI">
            <nz-form-item>
              <nz-form-label>How Many Questions do you want ?</nz-form-label>
              <nz-form-control><input formControlName="questionsForGen" nz-input type="number">
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label>Prompt</nz-form-label>
              <nz-form-control>  <textarea formControlName="prompt" nz-input
                                           placeholder="tell me about topic that you want to create questions"
                                           rows="4"></textarea>
              </nz-form-control>
            </nz-form-item>
            <div>
              <button (click)="onGenerate()" class="w-100" nz-button type="button"><span nz-icon nzTheme="outline"
                                                                                         nzType="bulb"></span>Generate
                Examination
              </button>
            </div>
          </nz-card>
        </form>

        <form [formGroup]="examinationForm" nz-form nzLayout="vertical">
          <ng-container formArrayName="exams">
            <div *ngFor="let i of examinationArray.controls; let index = index" class="choice-card  mt-24">
              <div class="d-flex d-jus-between">
                <div class="choice-card__running-number">{{ index + 1 }}</div>
                <div>
                  <button (click)="onDelete(index)" nz-button nzDanger
                          nzType="dashed">Delete
                  </button>
                </div>
              </div>
              <ng-container [formGroup]="$any(i)">
                <nz-form-item>
                  <nz-form-label nzRequired>Question</nz-form-label>
                  <nz-form-control>
                    <input formControlName="question" nz-input type="text">
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzRequired>Correct Answer</nz-form-label>
                  <nz-form-control>
                    <nz-select formControlName="correctAnswer">
                      <nz-option nzLabel="A" nzValue="a"></nz-option>
                      <nz-option nzLabel="B" nzValue="b"></nz-option>
                      <nz-option nzLabel="C" nzValue="c"></nz-option>
                      <nz-option nzLabel="D" nzValue="d"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
                <nz-row formArrayName="choicesAnswer" nzGutter="12">
                  <nz-col *ngFor="let c of $any(i).get('choicesAnswer').controls" nzSpan="12">
                    <ng-container [formGroup]="c">
                      <nz-form-item>
                        <nz-form-label nzRequired>{{ c.value.id | uppercase }}</nz-form-label>
                        <nz-form-control>
                          {{ i.value.correctAnswer }}
                          <input [class.correct-answer]="false" formControlName="answer" nz-input type="text">
                        </nz-form-control>
                      </nz-form-item>
                    </ng-container>

                  </nz-col>
                </nz-row>


              </ng-container>
            </div>
          </ng-container>
        </form>
        <div class="mt-24 d-flex d-jus-end">
          <button (click)="onAddQuestion()" nz-button><span nz-icon nzTheme="outline"
                                                            nzType="plus"></span>Add New Question
          </button>
        </div>
        <div class="w-100 mt-24">
          <button (click)="onCreate()" class="w-100" nz-button nzType="primary"><span nz-icon nzTheme="outline"
                                                                                      nzType="plus"></span>Create
          </button>
        </div>
      </div>
    </div>
  </div>
</nz-spin>
