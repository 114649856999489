import {Component, OnInit} from '@angular/core';
import {NgForOf, TitleCasePipe} from "@angular/common";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzTableModule, NzTableSortFn} from "ng-zorro-antd/table";
import {NzIconDirective} from "ng-zorro-antd/icon";
import {NzTagModule} from "ng-zorro-antd/tag";
import {RouterLink} from "@angular/router";
import {NzSelectModule} from "ng-zorro-antd/select";
import {FormsModule} from "@angular/forms";
import {JobService} from "../../../core/services/job/job.service";
import {NzFormModule} from "ng-zorro-antd/form";
import {ExaminationService} from "../../../core/services/examination/examination.service";
import {finalize} from "rxjs";
import {NotificationService} from "../../../shared/services/notification.service";

interface DataItem {
  id: string;
  name: string;
  status: string;
}

interface ListOfColumn {
  title: string;
  priority: boolean | number;
  showSort: boolean;
  compare: NzTableSortFn<any> | null;
  align: 'left' | 'center' | 'right' | null;
  width: string | null;
}

interface Job {
  id: string;
  label: string;
}

@Component({
  selector: 'app-release-list',
  standalone: true,
  imports: [
    NzTableModule,
    NgForOf,
    NzButtonModule,
    NzTagModule,
    RouterLink,
    NzIconDirective,
    NzSelectModule,
    FormsModule,
    NzFormModule,
    TitleCasePipe,
  ],
  templateUrl: './release-list.component.html',
  styleUrl: './release-list.component.scss'
})
export class ReleaseListComponent implements OnInit {
  listOfColumn: ListOfColumn[] = [
    {
      title: 'Name',
      compare: (a: DataItem, b: DataItem) => a.name.localeCompare(b.name),
      priority: false,
      showSort: false,
      align: null,
      width: null
    },
    {
      title: 'Status',
      compare: (a: DataItem, b: DataItem) => a.status.localeCompare(b.status),
      showSort: true,
      priority: false,
      align: 'center',
      width: '20%'
    },
    {
      title: '',
      showSort: false,
      priority: false,
      compare: null,
      align: null,
      width: '250px'
    }
  ];
  listOfData: DataItem[] = [];
  listOfJob: Job[] = [];
  jobSelected: string | null = null
  fetchJobLoading = false
  fetchReleaseLoading = false;

  constructor(
    private jobService: JobService,
    private examinationService: ExaminationService,
    private notificationService: NotificationService
  ) {

  }

  onActivateRelease(id: string): void {
    this.examinationService.activateRelease(id).subscribe({
      next: () => {
        this.notificationService.success('Activated', 'activate version success')
        this.fetchListData(false)
      },
      error: e => {
        this.notificationService.error('Failed to activate release', e.error.errorMsg)
      }
    })
  }

  ngOnInit() {
    this.fetchJobLoading = true;
    this.fetchListData(true);
  }

  onJobChange(id: string) {
    this.fetchExaminationList(id)
  }

  private fetchListData(reset?: boolean) {
    this.jobService.jobList(1, 100).pipe(
      finalize(() => {
        this.fetchJobLoading = false
      })
    ).subscribe({
      next: data => {
        this.listOfJob = data.data.map(item => {
          return {
            id: item.id,
            label: item.jobTitle
          }
        })
        if (this.listOfJob.length) {
          if (reset) {
            this.jobSelected = this.listOfJob[0].id
            this.fetchExaminationList(this.jobSelected)
          } else if (this.jobSelected) {
            this.fetchExaminationList(this.jobSelected)
          }
        }
      },
      error: e => {
        this.notificationService.apiError(e)
      }
    })
  }

  private fetchExaminationList(id: string) {
    this.fetchReleaseLoading = true;
    this.examinationService.getReleaseListByJobId(id).pipe(
      finalize(() => {
        this.fetchReleaseLoading = false;
      })
    ).subscribe({
      next: data => {
        console.log(data)
        this.listOfData = data.jobAssessVerList.map(i => {
          return {
            id: i.id,
            name: i.name,
            status: i.status
          }
        });
      },
      error: e => {
        this.notificationService.apiError(e)
      }
    })
  }
}
