import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {RankingResponse} from "./ranking.model";


@Injectable({providedIn: 'root'})
export class RankingService {
  constructor(private http: HttpClient) {
  }

  getRankingById(id: string, page: number, size: number): Observable<RankingResponse> {
    const url = `${environment.apiUrl}/applicants/with-scores-page/by-job-desc/${id}`;
    return this.http.get<RankingResponse>(url, {
      params: {
        page: page,
        size: size
      }
    })
  }


  getRanking(page: number, size: number): Observable<RankingResponse> {
    const url = `${environment.apiUrl}/applicants/with-scores-page`;
    return this.http.get<RankingResponse>(url, {
      params: {
        page: page,
        size: size
      }
    })
  }
}
