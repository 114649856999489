<div class="job-list-page">
  <div class="job-list-page__content">
    <div><h2>Career Listings
    </h2>

    </div>
    <nz-table #sortTable [nzData]="listOfData" [nzLoading]="loading" nzTableLayout="fixed">
      <thead>
      <tr>
        <th *ngFor="let column of listOfColumn" [nzAlign]="column.align" [nzShowSort]="column?.showSort"
            [nzSortFn]="column.compare"
            [nzWidth]="column.width">
          {{ column.title }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of sortTable.data">
        <td>{{ data.jobTitle }}</td>
        <td nzAlign="center">{{ data.numberOfApplicant }}</td>
        <td nzAlign="center">
          @switch (data.status) {
            @case ('ACTIVE') {
              <nz-tag [nzColor]="'rgb(45,203,78)'">{{ data.status | titlecase }}</nz-tag>
            }
            @default {
              <nz-tag [nzColor]="'rgb(92 92 92)'">{{ data.status  | titlecase }}</nz-tag>
            }
          }
        </td>
        <td nzAlign="center">
          <div class="d-flex d-jus-center" style="gap: 24px">
            <button [routerLink]="['/','ranking',data.id]" nz-button><span nz-icon nzTheme="outline"
                                                                           nzType="ordered-list"></span>Ranking
            </button>
            <button [routerLink]="['/','edit-job',data.id]" nz-button><span nz-icon nzTheme="outline"
                                                                            nzType="file-text"></span>
              Detail
            </button>
            <button disabled nz-button><span nz-icon nzTheme="outline" nzType="delete"></span>Delete</button>
          </div>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</div>
