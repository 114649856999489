import {Component, OnInit} from '@angular/core';
import {PdfViewerModule} from "ng2-pdf-viewer";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzCommentModule} from "ng-zorro-antd/comment";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzAvatarModule} from "ng-zorro-antd/avatar";
import {NzProgressModule} from "ng-zorro-antd/progress";
import {interval, map, Observable, of, take} from "rxjs";
import {AsyncPipe, DecimalPipe, Location, NgIf} from "@angular/common";
import {NzStatisticModule} from "ng-zorro-antd/statistic";
import {NzButtonModule} from "ng-zorro-antd/button";
import {CandidateService} from "../../core/services/candidate/candidate.service";
import {ActivatedRoute} from "@angular/router";
import {convertBase64ToImage} from "../../helpers/base64";

interface Candidate {
  name: string;
  mail: string;
  id: string;
  mobile: string;
  skillMatch: number
  expMatch: number
  educationMatch: number
  softSkillMatch: number
  jobTitleMatch: number
  overallMatch: number
  conclusion: string;
  jobTitle: string
  image: string
}

@Component({
  selector: 'app-candidate',
  standalone: true,
  imports: [PdfViewerModule, NzFormModule, NzCommentModule, NzIconModule, NzAvatarModule, NzProgressModule, AsyncPipe, NzStatisticModule, DecimalPipe, NzButtonModule, NgIf],
  templateUrl: './candidate.component.html',
  styleUrl: './candidate.component.scss'
})
export class CandidateComponent implements OnInit {
  pdfSrc: string | Uint8Array | null
  candidate?: Candidate;
  score: Observable<number>;
  chart: any

  constructor(
    private location: Location,
    private candidateService: CandidateService,
    private route: ActivatedRoute,
  ) {
    this.pdfSrc = '';
    this.score = of(0);
  }

  onBack() {
    this.location.back()
  }

  ngOnInit() {
    const source = interval(10);
    this.candidateService.candidateProfile(this.route.snapshot.params['id']).subscribe({
      next: data => {
        console.log(data)
        this.candidate = {
          id: data.applicantId,
          name: data.fullName,
          mobile: data.phoneNo,
          mail: data.email,
          educationMatch: data.educationMatch,
          expMatch: data.expMatch,
          jobTitleMatch: data.jobTitleMatch,
          overallMatch: data.overallMatch,
          skillMatch: data.skillMatch,
          softSkillMatch: data.softSkillMatch,
          conclusion: data.conclusion,
          jobTitle: data.jobTitle,
          image: convertBase64ToImage(data.imageBase64)
        }
        this.score = source.pipe(
          take(data.overallMatch),
          map(value => value + 1)
        )

        this.candidateService.getResume(this.route.snapshot.params['id']).subscribe({
          next: blob => {
            const reader = new FileReader();
            reader.onload = () => {
              const arrayBuffer = reader.result as ArrayBuffer;
              this.pdfSrc = new Uint8Array(arrayBuffer);
            };
            reader.readAsArrayBuffer(blob);
          }
        })
      }
    })
    // this.chart = new Chart('canvas', {
    //   type: 'radar',
    //   data: {
    //     labels: ['Skills', 'Experience', 'Education', 'Soft Skills', 'Job Title'],
    //     datasets: [
    //       {
    //         data: [75, 89, 90, 81, 76],
    //         fill: true,
    //         backgroundColor: 'rgba(255, 99, 132, 0.2)',
    //         borderColor: 'rgb(255, 99, 132)',
    //         pointBackgroundColor: 'rgb(255, 99, 132)',
    //         pointBorderColor: '#fff',
    //         pointHoverBackgroundColor: '#fff',
    //         pointHoverBorderColor: 'rgb(255, 99, 132)'
    //       }
    //     ],
    //   },
    //   options: {
    //     responsive: true,
    //     plugins: {
    //       legend: {
    //         display: false
    //       }
    //     },
    //     scales: {
    //       r: {
    //         angleLines: {
    //           display: false
    //         },
    //         suggestedMin: 20,
    //         suggestedMax: 100
    //       }
    //     }
    //   },
    // });
  }

}
