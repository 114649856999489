<div class="release-list">
  <div class="release-list__content">
    <div class="d-flex d-jus-between">
      <div><h2>Releases</h2></div>
      <div>
        <button [routerLink]="[this.jobSelected,'create']" nz-button><span nz-icon nzTheme="outline"
                                                                           nzType="plus"></span>Create New Release Exams
        </button>
      </div>
    </div>

    <div>
      <form nz-form>
        <nz-form-item>
          <nz-form-label>
            Career
          </nz-form-label>
          <nz-form-control nzSpan="16">
            <nz-select (ngModelChange)="onJobChange($event)" [(ngModel)]="jobSelected"
                       [ngModelOptions]="{standalone: true}"
                       [nzLoading]="fetchJobLoading">
              @for (i of listOfJob; track i.id) {
                <nz-option [nzValue]="i.id" [nzLabel]="i.label"></nz-option>
              }
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
    <nz-table #sortTable [nzData]="listOfData" [nzLoading]="fetchReleaseLoading" nzTableLayout="fixed">
      <thead>
      <tr>
        <th *ngFor="let column of listOfColumn" [nzAlign]="column.align" [nzShowSort]="column?.showSort"
            [nzSortFn]="column.compare"
            [nzWidth]="column.width">
          {{ column.title }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of sortTable.data">
        <td>{{ data.name }}</td>
        <td [nzAlign]="'center'">
          @switch (data.status) {
            @case ('ACTIVE') {
              <nz-tag [nzColor]="'rgb(45,203,78)'">{{ data.status | titlecase }}</nz-tag>
            }
            @case ('INACTIVE') {
              <nz-tag [nzColor]="'rgb(129,129,129)'">{{ data.status | titlecase }}</nz-tag>
            }
            @default {
              <nz-tag [nzColor]="'rgb(245,157,110)'">{{ data.status  | titlecase }}</nz-tag>
            }
          }
        </td>
        <td [nzAlign]="'center'">
          <div class="d-flex" style="gap: 24px">
            <button [routerLink]="[data.id,jobSelected]" nz-button><span nz-icon nzTheme="outline"
                                                                         nzType="file-text"></span>Detail
            </button>
            <button (click)="onActivateRelease(data.id)" [disabled]="data.status === 'ACTIVE'" nz-button><span nz-icon
                                                                                                               nzTheme="outline"
                                                                                                               nzType="file-done"></span>Activate
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</div>
