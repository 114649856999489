import {Component, OnInit} from '@angular/core';
import {NzFormModule} from "ng-zorro-antd/form";
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzRadioModule} from "ng-zorro-antd/radio";
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {NgForOf} from "@angular/common";

interface Exam {
  id: string;
  question: string;
  choices: Choice[];
}

interface Choice {
  id: string;
  label: string;
}

@Component({
  selector: 'app-exam',
  standalone: true,
  imports: [NzFormModule, NzGridModule, NzRadioModule, ReactiveFormsModule, NgForOf],
  templateUrl: './exam.component.html',
  styleUrl: './exam.component.scss'
})
export class ExamComponent implements OnInit {
  exams: Exam[] = [];
  form: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      exams: this.formBuilder.array([])
    })
    this.exams = [
      {
        id: '1',
        question: 'What is the purpose of the flex property in CSS?',
        choices: [
          {
            id: 'A',
            label: 'Align elements vertically'
          },
          {
            id: 'B',
            label: 'Control the layout of elements inside a flex container'
          }, {
            id: 'C',
            label: 'Change the background color of elements'
          },
          {
            id: 'D',
            label: 'Add padding to elements'
          }
        ]
      }
    ];
  }

  get examFormArray(): FormArray {
    return this.form.get('exams') as FormArray;
  }

  ngOnInit() {
    this.buildForm()
  }

  buildForm() {
    this.exams.forEach(i => {
      const ar = this.form.get('exams') as FormArray;
      ar.push(this.formBuilder.group({
        id: i.id,
        answer: ''
      }))
    })

    console.log(this.form)
  }
  
}
