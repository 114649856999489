<div style="margin-bottom: 16px">
  <button (click)="onBack()" nz-button><span nz-icon nzTheme="outline" nzType="arrow-left"></span>Back</button>
</div>
<div class="ranking-page">
  <div class="ranking-page__content">
    <div><h2>Ranking</h2>

    </div>
    <nz-table #sortTable [nzData]="listOfData" [nzLoading]="loading" nzTableLayout="fixed">
      <thead>
      <tr>
        <th *ngFor="let column of listOfColumn" [nzAlign]="column.align" [nzShowSort]="column?.showSort"
            [nzSortFn]="column.compare"
            [nzWidth]="column.width">
          {{ column.title }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of sortTable.data">
        <td>{{ data.name }}</td>
        <td [nzAlign]="'center'">
          @switch (data.status) {
            @case ('Pending Scheduling') {
              <nz-tag [nzColor]="'rgb(92 92 92)'">{{ data.status }}</nz-tag>
            }
            @case ('Awaiting Interview') {
              <nz-tag [nzColor]="'rgb(211 16 194)'">{{ data.status }}</nz-tag>
            }
            @case ('Interview Completed') {
              <nz-tag [nzColor]="'#119aae'">{{ data.status }}</nz-tag>
            }
            @case ('Not Selected') {
              <nz-tag [nzColor]="'rgb(248 67 67)'">{{ data.status }}</nz-tag>
            }
            @case ('Moved Forward') {
              <nz-tag [nzColor]="'rgb(13 158 107)'">{{ data.status }}</nz-tag>
            }
          }
        </td>
        <td [nzAlign]="'center'">{{ data.score }}%</td>
        <td>
          <button [routerLink]="['/','candidate',data.id]" nz-button>Detail</button>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</div>
