<div class="main-layout">
  <div id="menu">
    <ul nz-menu nzMode="inline">
      <div class="brand">AI Recruit</div>
      <li nz-menu-group nzTitle="Menu">
        <ul>
          <li nz-menu-item nzDisabled>
            <div>
              <span class="menu-icon" nz-icon nzTheme="outline" nzType="dashboard"></span>Dashboard
            </div>
          </li>
          <li nz-menu-item nzMatchRouter routerLink="create-job">
            <div><span class="menu-icon" nz-icon nzTheme="outline" nzType="diff"></span>New Career Posting</div>
          </li>
          <li nz-menu-item nzMatchRouter routerLink="job-list">
            <div><span class="menu-icon" nz-icon nzTheme="outline" nzType="container"></span>Career Listings</div>
          </li>
          <li nz-menu-item nzMatchRouter routerLink="company-list">
            <div>
              <span class="menu-icon" nz-icon nzTheme="outline" nzType="apartment"></span>Company Profile
            </div>
          </li>
          <li nz-menu-item nzMatchRouter routerLink="examination/release">
            <div>
              <span class="menu-icon" nz-icon nzTheme="outline" nzType="experiment"></span>Candidate
              Exams
            </div>
          </li>
          <li nz-menu-item nzDisabled>
            <div>
              <span class="menu-icon" nz-icon nzTheme="outline" nzType="user"></span>Candidate
            </div>
          </li>
        </ul>
      </li>
      <!--      <li nz-menu-group nzTitle="Candidate">-->
      <!--        <ul>-->
      <!--          <li nz-menu-item nzMatchRouter routerLink="ranking">Ranking</li>-->
      <!--          <li nz-menu-item nzMatchRouter>Stats</li>-->
      <!--        </ul>-->
      <!--      </li>-->
    </ul>

  </div>
  <div #content class="main-layout__content" id="content">
    <router-outlet></router-outlet>
  </div>
</div>

<div (click)="onGoToTop()" *ngIf="isVisible" class="back-to-top"><span nz-icon nzTheme="outline" nzType="to-top"></span>
</div>
